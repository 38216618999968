<template>
  <div class="notice">
    <cms-header></cms-header>
    <!--面包屑-->
    <div class="width_width child_bg_1">
      <div class="width_w clearfix">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>

          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link to="/member">
            <a class="now_a left" href="#">会员中心</a>
          </router-link>
        </div>
      </div>
      <span class="child_name">会员中心</span>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left">
        <ul class="tab_con clearfix">
          <li v-for="(item,index) in memberTypeList" :key="'member-'+index">
            <a href="#" :class="activeClass == index ? 'tab_active':''" class="tab_li"
               @click="getItem(index,item,'activeClass')">{{ item.categoryTitle }}</a>
          </li>
        </ul>
        <div class="tender_con">
          <div class="news_child_1" v-html="content.contentDetails">

          </div>
        </div>
      </div>
      <div class="notice-right right cms-sm-hidden cms-ssm-hidden cms-md-hidden">
        <div class="child_tool">
          <!--工具栏-->
          <toolbar></toolbar>
          <!--联系卡片-->
          <contact-card></contact-card>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import Toolbar from "../../components/commoon/component/toolbar";
import ContactCard from "../../components/commoon/component/contact-card";
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";

export default {
  name: "member",
  components: {CmsFoot, CmsHeader, Toolbar, ContactCard},
  data() {
    return {
      memberTypeList: [],
      content: {},
      activeClass: 0,
      activeClass1: 0,
      activeClass2: 0,
    }
  }, methods: {
    /*选中按钮*/
    getItem: function (index, item, activeClassName) {
      this[activeClassName] = index;
      this.selectContent(item.id)
    },
    /*查询栏目*/
    selectCategoryList: function (modelId, name, id) {
      var that = this;
      that.$http.get(that.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
        if (id != null) {
          for (let i = 0; i < data.data.result.length; i++) {
            if (id == data.data.result[i].id) {
              that.selectContent(id)
              that.activeClass = i
            }
          }
        } else {
          that.selectContent(data.data.result[0].id)
        }
      }).catch(function (error) {

      })
    },
    /*查询文章*/
    selectContent: function (id) {
      var that = this;
      that.$http.post(that.manager + "/content/page.do", {
        page: {
          current: 0,
          size: 10
        },
        cmsContentDTO: {
          contentCategoryId: id
        }
      }).then(function (res) {
        that.content = res.data.result.records[0];
      }).catch(function (err) {
        that.loading = false;
      });
    }, getParameter: function (paraName) {
      var url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },

  }, created() {
    this.activeClass = this.getParameter("index");
    if (this.activeClass == null || this.activeClass == "") {
      this.activeClass = 0;
    }
    /*初始化选项列表*/
    this.selectCategoryList("member_type", "memberTypeList");
    document.title = this.title;

  }
}
</script>

<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
